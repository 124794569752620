import { useRef, useLayoutEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";

interface SwitcherProps {
  ComponentOne: JSX.Element;
  ComponentTwo: JSX.Element;
  switchPosition: boolean;
}

/**
 * Used to switch between visible components
 * ```
 * <ComponentSwitcher
 *    switchPosition={true} // will display ComponentOne
 *    ComponentOne={<Typography>First Component</Typography>}
 *    ComponentTwo={<Typography>Second Component</Typography>}
 * />
 * ```
 */
export function ComponentSwitcher({
  ComponentOne,
  ComponentTwo,
  switchPosition = false,
}: SwitcherProps) {
  const [height, setHeight] = useState(64);
  const containerRef = useRef<HTMLElement>(null);
  const refOne = useRef<HTMLElement>(null);
  const refTwo = useRef<HTMLElement>(null);
  const absoluteSx = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  };
  const observer = useRef(
    new ResizeObserver((entries) => {
      const boxHeight = entries?.reduce((max, current) => {
        const currentHeight = current?.contentRect.height;
        return currentHeight > max ? currentHeight : max;
      }, 0);
      setHeight(boxHeight);
    })
  );

  useLayoutEffect(() => {
    if (refOne.current && refTwo.current) {
      observer.current.observe(refOne.current);
      observer.current.observe(refTwo.current);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      observer.current.disconnect();
    };
  }, [observer]);

  return (
    <Box position="relative" height={height} ref={containerRef}>
      <Slide
        appear={false}
        in={switchPosition}
        direction="right"
        container={containerRef.current}
      >
        <Box sx={absoluteSx}>
          <Box ref={refOne}>{ComponentOne}</Box>
        </Box>
      </Slide>
      <Slide
        appear={false}
        in={!switchPosition}
        direction="left"
        container={containerRef.current}
      >
        <Box sx={absoluteSx}>
          <Box ref={refTwo}>{ComponentTwo}</Box>
        </Box>
      </Slide>
    </Box>
  );
}
