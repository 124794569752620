import { ChangeEvent } from "react";
import Stack from "@mui/material/Stack";
import Switch, { switchClasses } from "@mui/material/Switch";
import Typography, { TypographyProps } from "@mui/material/Typography";

interface SwitchySwitchProps {
  isPositionOne: boolean;
  positionOneText: string;
  positionTwoText: string;
  switchOnChange: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  typographyProps?: TypographyProps;
  testId?: string;
}

export function SwitchySwitch({
  isPositionOne = true,
  switchOnChange,
  positionOneText,
  positionTwoText,
  testId = "switchy-switch",
  typographyProps,
}: SwitchySwitchProps) {
  const isPositionTwo = !isPositionOne;
  return (
    <Stack direction="row" alignItems="center">
      <Typography
        variant="subtitle2"
        {...typographyProps}
        sx={{
          fontWeight: isPositionOne ? "bold" : "normal",
          ...typographyProps?.sx,
        }}
      >
        {positionOneText}
      </Typography>
      <Switch
        data-testid={testId}
        checked={isPositionTwo}
        onChange={switchOnChange}
        sx={{
          [`& .${switchClasses.track}`]: {
            color: (theme) => theme.palette.primary.main,
            backgroundColor: (theme) => theme.palette.primary.main,
          },
          [`& .${switchClasses.thumb}`]: {
            color: (theme) => theme.palette.primary.main,
            backgroundColor: (theme) => theme.palette.primary.main,
          },
        }}
      />
      <Typography
        variant="subtitle2"
        {...typographyProps}
        sx={{
          fontWeight: isPositionTwo ? "bold" : "normal",
          ...typographyProps?.sx,
        }}
      >
        {positionTwoText}
      </Typography>
    </Stack>
  );
}
