import { BodyJSONData } from "core/entities";
import { APIFetch } from "core/utils/APIFetch";
import { objectToFormData } from "core/utils/objectToFormData";
import { validateResponse } from "core/utils/validateResponse";
import { useAPIHeaders } from "./useAPIHeaders";

export function useAPIPostFormData() {
  const headers = useAPIHeaders();
  return async <T = any>(path: string, data: BodyJSONData): Promise<T> => {
    const res = await APIFetch(path, {
      method: "POST",
      body: objectToFormData(data),
      headers: { ...headers },
    });
    const jsonResponse = await res.json();
    await validateResponse(res);
    return jsonResponse;
  };
}
