import { styled } from "@mui/material/styles";
import Tabs, { TabsProps } from "@mui/material/Tabs";

export const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  "& .MuiTab-root": {
    transition: "200ms ease",
    "&.Mui-selected": { color: theme.palette.primary.contrastText, zIndex: 1 },
  },
  "& .MuiTabs-indicator": {
    height: 48,
    borderRadius: "5px 5px 0px 0px",
    borderBlockEnd: `3px solid ${theme.palette.secondary.main}`,
  },
}));
