import { useTranslation } from "react-i18next";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { MouseEventHandler } from "react";

interface SubmitButtonProps extends LoadingButtonProps {
  loading?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export function SubmitButton({
  loading = false,
  disabled = false,
  onClick,
  children,
  ...rest
}: SubmitButtonProps & { children?: React.ReactNode }) {
  const { t } = useTranslation();
  return (
    <LoadingButton
      variant="contained"
      color="primary"
      type="submit"
      loadingPosition="start"
      loading={loading}
      startIcon={<SendIcon />}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {children ?? t("Next:Core:Submit")}
    </LoadingButton>
  );
}
